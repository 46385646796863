export const dateFormat = new Intl.DateTimeFormat('en-US', {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
});

export const customEvent = (category, action, label) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    'event': 'customEvent',
    'category': category,
    'action': action,
    'label': label
  })
}

export const getCurrentDate = () => {
  const dt = new Date();

  dt.setTime(dt.getTime()+dt.getTimezoneOffset()*60*1000);
  
  let offset = -300; //Timezone offset for EST in minutes.
  let estDate = new Date(dt.getTime() + offset*60*1000);
        // console.log(estDate); //Gives Mon Mar 21 2016 23:00:00 GMT+0530 (IST)
  const date = dateFormat.format(new Date(estDate))
  
  return date;
}